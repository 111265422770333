export const MESSAGE_PREFIX = "apps.submission-wizard.";

// Consent to be contacted by the contest host is obtained before the user even reaches the
// new submission wizard. Therefore this will be true for all users inside the new submission
// wizard.
export const hasConsentedToBeContactedByContestHost = true;

export const GROWTHBOOK_FEATURE_SHOW_WIZARD_ALPHA_VERSION_CALLOUT =
  "show-wizard-alpha-version-callout";

/**
 *  Storage key generators for local storage
 */
export const StorageKeyGenerators = {
  /**
   * Local Storage Key for showing the dialog to add additional projects
   */
  showAddOptionalProjectDialog: (submissionId = "new") =>
    `PICTER__SHOW_PROMPT__${submissionId}`,

  /**
   * Local Storage key for storing user's preference to not ask again to add additional projects
   * for the current submission
   */
  dontAskAgainToAddOptionalProjects: (submissionId = "new") =>
    `PICTER__DONT_ASK_AGAIN__${submissionId}`,

  /**
   * Local Storage key for storing the form data for the profile form
   */
  profileForm: (submissionId = "new") => `PICTER__PROFILE_FORM-${submissionId}`,

  /**
   * Local Storage key for storing the form data for the project form
   */
  projectForm: ({
    submissionId,
    categoryId,
    projectId = "new",
  }: {
    submissionId: string;
    categoryId: string;
    projectId?: string;
  }) => `PICTER__PROJECT_FORM-${submissionId}-${categoryId}--${projectId}`,

  /**
   * Local Storage key for storing assets for the project form
   */
  projectAssets: ({
    submissionId = "new",
    categoryId,
    projectId = "new",
  }: {
    submissionId?: string;
    categoryId: string;
    projectId?: string;
  }) =>
    `PICTER__PROJECT_FORM-${submissionId}-${categoryId}--${projectId}--ASSETS`,
};
