import { PropsWithChildren } from "react";

import { Alert, Text } from "@shared/elements";

type CalloutProps = PropsWithChildren<{
  Icon: React.ComponentType<{ className?: string }>;
  title: string;
  description: string;
  /**
   * (optional) Will show a link at the bottom of the callout
   */
  link?: string;
  /**
   * (optional) - The text of the link element at the bottom of the callout
   */
  linkText?: string;
}>;

export default function Callout({
  Icon,
  title,
  description,
  link,
  linkText,
  children,
}: CalloutProps) {
  return (
    <Alert color="primary" Icon={Icon}>
      <div className="flex flex-col gap-3 font-medium">
        <Text size="sm" weight="medium" color="primary">
          {title}
        </Text>
        <Text size="sm" color="primary" weight="regular">
          {description}
        </Text>
        {link && linkText && (
          <Text
            size="sm"
            color="primary"
            as="a"
            // @ts-ignore TODO: fix typing in Text component to allow extending props depending on the as prop
            href={link}
            weight="medium"
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </Text>
        )}
        {children}
      </div>
    </Alert>
  );
}
