import { PoweredBy } from "@shared/components";
import NeedHelp from "@shared/components/src/NeedHelp";

export default function Footer() {
  return (
    <footer className="my-5">
      <hr className="my-8" />
      <PoweredBy />
      <NeedHelp className="mt-5" />
    </footer>
  );
}

Footer.messages = [...PoweredBy.messages, ...NeedHelp.messages];
