import classNames from "clsx";
import { useTranslations } from "next-intl";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { useIntercom } from "react-use-intercom";

import { Text } from "@shared/elements";


import * as constants from "../constants";

type NeedHelpProps = {
  className?: string;
};

export default function NeedHelp({ className, ...rest }: NeedHelpProps) {
  const { show: showIntercom } = useIntercom();
  const t = useTranslations(`${constants.MESSAGE_PREFIX}NeedHelp`);
  return (
    <Text
      as="div"
      size="sm"
      className={classNames("flex items-center", className)}
      {...rest}
    >
      <HiQuestionMarkCircle
        className="mr-1 inline-block text-primary-600"
        size="1rem"
      />
      <button
        type="button"
        className="font-medium text-primary-600 hover:text-primary-500 cursor-pointer"
        onClick={showIntercom}
      >
        {t("label")}
      </button>
    </Text>
  );
}

NeedHelp.messages = [`${constants.MESSAGE_PREFIX}NeedHelp`];
