import { TCall } from "__generated__/types";

/**
 * Returns true if the call allows editing of submissions and
 * the call is still open
 */
export function checkIsCallEditable(
  call?: Pick<TCall, "submissionEndDate" | "allowsRevocation">,
) {
  if (!call) return null;

  const isOpen = call.submissionEndDate > new Date().toISOString();
  const isEditable = call.allowsRevocation && isOpen;
  return isEditable;
}
