import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useTranslations } from "next-intl";
import { HiChat, HiLogin } from "react-icons/hi";

import { urls } from "@shared/constants";

import {
  GROWTHBOOK_FEATURE_SHOW_WIZARD_ALPHA_VERSION_CALLOUT,
  MESSAGE_PREFIX,
  hasConsentedToBeContactedByContestHost,
} from "src/constants";

import Callout from "./Callout";

type AlphaVersionCalloutsProps = {
  callId?: string;
  isHideSwitchBackToOldInterfaceCallout?: boolean;
  isShowDivider?: boolean;
};

export default function AlphaVersionCallouts({
  callId,
  isHideSwitchBackToOldInterfaceCallout = false,
  isShowDivider = false,
}: AlphaVersionCalloutsProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}components.AlphaVersionCallouts`);
  const surveyLink = `https://docs.google.com/forms/u/7/d/e/1FAIpQLSchYJnh4HNLKDqLfAJckkwRyiHHSrZLP1aBEWNjt-KMTeHVQg/viewform?usp=send_form`;

  const isShowAlphaVersionCallouts = useFeatureIsOn(
    GROWTHBOOK_FEATURE_SHOW_WIZARD_ALPHA_VERSION_CALLOUT,
  );

  if (!isShowAlphaVersionCallouts) {
    return null;
  }
  return (
    <>
      {isShowDivider && <hr className="my-2" />}
      <Callout
        title={t("welcomeTitle")}
        description={t("welcomeDescription")}
        linkText={t("surveyLinkText")}
        link={surveyLink}
        Icon={HiChat}
      />
      {!isHideSwitchBackToOldInterfaceCallout && callId && (
        <Callout
          title={t("switchBackToOldInterfaceTitle")}
          description={t("switchBackToOldInterfaceDescription")}
          linkText={t("switchBackToOldInterfaceLinkText")}
          link={urls.startSubmission(
            callId,
            hasConsentedToBeContactedByContestHost,
          )}
          Icon={HiLogin}
        />
      )}
    </>
  );
}

AlphaVersionCallouts.messages = [
  `${MESSAGE_PREFIX}components.AlphaVersionCallouts`,
];
