import { PropsWithChildren } from "react";

import { Container } from "@shared/elements";

import { TWizardAppFields } from "__generated__/types";

import AlphaVersionCallouts from "./AlphaVersionCallouts";
import Footer from "./Footer";
import Header from "./Header";

type LayoutProps = PropsWithChildren<{
  call: TWizardAppFields | undefined;
}>;

export default function Layout({ call, children }: LayoutProps) {
  const { logoDefault, organisation, website } = call || {};

  const orgName = organisation?.name?.en || "";
  const logoUrl = logoDefault ?? undefined;
  const logoAlt =
    logoUrl && logoDefault ? `${orgName}'s logo` : "Organization's logo";
  const sanitizedWebsite = website ?? "/";

  return (
    <Container className="max-w-2xl p-8 min-h-screen relative">
      <Header
        orgName={orgName}
        logoUrl={logoUrl}
        logoAlt={logoAlt}
        website={sanitizedWebsite}
      />
      <main className="flex flex-col gap-6">
        {children}
        <AlphaVersionCallouts
          callId={call?.id}
          isShowDivider
        ></AlphaVersionCallouts>
      </main>

      <Footer />
    </Container>
  );
}

Layout.messages = [...Footer.messages, ...AlphaVersionCallouts.messages];
