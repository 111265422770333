import Link from "next/link";

type HeaderProps = {
  /**
   * The name of the organization
   */
  orgName?: string;
  /**
   * The URL to the organization's logo
   */
  logoUrl?: string;
  /**
  /**
   * Alternative text for the logo
   */
  logoAlt?: string;
  /**
   * The URL to the organization's website
   */
  website: string;
};

export default function Header({
  orgName,
  website,
  logoUrl,
  logoAlt,
}: HeaderProps) {
  if (!logoUrl) return null;

  return (
    <header>
      <Link href={website} target="_blank" rel="noopener noreferrer">
        {orgName && <span className="sr-only">{orgName}</span>}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img className="h-20 w-auto" src={logoUrl} alt={logoAlt} />
      </Link>
      <hr className="my-8" />
    </header>
  );
}
