import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { AbstractIntlMessages, NextIntlClientProvider } from "next-intl";
import { useEffect } from "react";

import { useUser } from "@shared/auth";
import { CookieBanner, PoweredBy } from "@shared/components";
import { urls } from "@shared/constants";
import { useGraphSWR } from "@shared/hooks";
import IntercomProvider from "@shared/providers/IntercomProvider";
import MyGrowthBookProvider from "@shared/providers/MyGrowthBookProvider";
import {
  areCookiesAccepted,
  acceptAllCookies,
  pageView,
  storeUtmParams,
} from "@shared/tracking";

import {
  MySubmission,
  TMySubmission,
  TSubmissionStatus,
  TWizardAppFields,
} from "__generated__/types";

import "src/styles/globals.css";
import Layout from "src/components/Layout";

import { ErrorProvider } from "src/context/errorContext";
import { checkIsCallEditable } from "src/utils/call";

export default function App({
  Component,
  pageProps,
}: AppProps<{ call?: TWizardAppFields; messages: AbstractIntlMessages }>) {
  const { call } = pageProps;
  useEffect(() => {
    // If the user arrived with utm params, store them in session storage
    // so we can pass them on to the next app. This is currently only used
    // to bind them to the submission-start event in Mixpanel, which allows
    // us to track the source of a submission.
    storeUtmParams(window.location.search.split("?")[1]);
    // If the user has already accepted cookies, we can immediately
    // tell Google Analytics about this consent, so it can start tracking.
    if (areCookiesAccepted()) {
      acceptAllCookies();
    }
  }, []);

  // Setup page view tracking
  // TODO: Check if this is still needed or if gtag is actually doing this
  // automatically
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  // Check if user is logged in
  // TODO: This will also kick in on error pages. Can we avoid this?
  // We can't add an if-statement before hooks, but we could set `redirectTo`
  // to undefined in case of an error page to avoid the redirect.
  useUser({
    redirectTo: call ? urls.submissionLogin(call.id) : urls.login,
    returnAfterAuthentication: true,
  });

  const isLayoutHidden =
    router.pathname.endsWith("success") ||
    router.pathname === "/[callSlug]" ||
    Component.name === "Error";

  const {
    data,
    mutate: revalidateSubmission, // giving /profile, /content a chance to revalidate
  } = useGraphSWR<TMySubmission>(
    MySubmission,
    {
      callId: call?.id,
    },
    {
      onSuccess: ({ mySubmission }: TMySubmission) => {
        // (onRequestSuccess) If there's no submission, redirect user to `[callSlug]/index`,
        // which is the page where the user can start a new submission.
        // We should not redirect if the user is already on the target route
        if (!mySubmission && router.asPath !== `/${call?.slug}`) {
          router.push(`/${call?.slug}`); // redirect to /[callSlug]
        }

        const isEditable = checkIsCallEditable(call);

        //redirect to success page
        if (
          // if the submission is already submitted
          mySubmission?.status === TSubmissionStatus.Submitted &&
          // and only if the user is not already on the success page
          router.asPath !== `/${call?.slug}/success` &&
          // and if the call is not editable
          !isEditable
        ) {
          router.push(`/${call?.slug}/success`); // redirect to /[callSlug]/success
        }
      },
    },
  );

  const mySubmission = data?.mySubmission;

  const renderComponent = () => (
    <ErrorProvider>
      <Component
        {...pageProps}
        submission={mySubmission}
        revalidateSubmission={revalidateSubmission}
      />
    </ErrorProvider>
  );

  return (
    <>
      <Head>
        <meta property="og:type" content="website" key="og-type" />
        <meta property="og:site_name" content="Picter" key="og-site_name" />
      </Head>
      <NextIntlClientProvider
        messages={pageProps.messages}
        locale="en"
        // Get the current timezone from the browser
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      >
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string}
          autoBoot
          initializeDelay={2000}
          autoBootProps={{ hideDefaultLauncher: true }}
        >
          <MyGrowthBookProvider callId={call?.id}>
            {isLayoutHidden ? (
              renderComponent()
            ) : (
              <Layout call={call}>
                <ErrorProvider>{renderComponent()}</ErrorProvider>
              </Layout>
            )}

            {/*
              For some reason, messages can be undefined sometimes. This seems to
              happen on revalidation only (when the json payload of a page is requested)
            */}
            {pageProps.messages && <CookieBanner />}
          </MyGrowthBookProvider>
        </IntercomProvider>
      </NextIntlClientProvider>
    </>
  );
}

App.messages = [
  ...CookieBanner.messages,
  ...PoweredBy.messages,
  ...Layout.messages,
];
