import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export type TErrorMessage = {
  title?: string;
  message: string;
};

type TErrorContext = {
  errors: TErrorMessage[];
  handleError: (err: unknown) => void;
  removeError: (message: string) => void;
  resetErrors: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorContext = createContext<TErrorContext>(null!); // Non-null assertion because we'll always provide a value.

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  // Reset errors when unmounting (e.g. on page change), so that
  // we don't show errors from the previous page
  useEffect(() => {
    return () => {
      context.resetErrors();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (!context) {
    throw new Error("useErrorContext must be used within an ErrorProvider");
  }
  return context;
};

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [errors, setErrors] = useState<TErrorMessage[]>([]);

  const removeError = useCallback((message: string) => {
    setErrors((prevErrors) =>
      prevErrors.filter((error) => error.message !== message),
    );
  }, []);

  const handleError = useCallback((err: unknown) => {
    console.error(err);
    // if it's a graphql error, extract the messages
    if (isGraphQLError(err)) {
      const messages: TErrorMessage[] = err.response.errors.map(
        ({ message }) => ({
          // If it's an input error, it looks very ugly, so we'll replace it with a more user-friendly(?) message
          message: message.includes('Variable "$') ? "Bad Request" : message,
        }),
      );
      setErrors((current) => [...current, ...messages]);
      return;
    }

    // if it's a custom error or any other error
    const { title, message } = isTErrorMessage(err)
      ? err
      : { title: undefined, message: "Please try again later." };

    setErrors((current) => [
      ...current,
      {
        title,
        message,
      },
    ]);
  }, []);

  const resetErrors = useCallback(() => setErrors([]), []);

  // filter out duplicate errors
  const uniqueErrors = errors.filter(
    (error, index, self) =>
      index === self.findIndex((e) => e.message === error.message),
  );

  return (
    <ErrorContext.Provider
      value={{ errors: uniqueErrors, handleError, removeError, resetErrors }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

type GraphQLError = {
  response: {
    errors: {
      message: string;
    }[];
  };
};

// Helper functions
const isGraphQLError = (err: unknown): err is GraphQLError => {
  if (typeof err !== "object" || err === null) return false;

  const response = (err as GraphQLError).response;
  if (typeof response !== "object" || response === null) return false;

  const { errors } = response;
  if (!Array.isArray(errors)) return false;

  return errors.every((error) => typeof error.message === "string");
};

const isTErrorMessage = (message: unknown): message is TErrorMessage => {
  if (typeof message !== "object" || message === null) return false;

  const { title, message: msg } = message as TErrorMessage;
  if (typeof msg !== "string") return false;

  if (title && typeof title !== "string") return false;

  return true;
};
